<template>
  <aside class="aside">
    <el-menu
      unique-opened
      :default-active="
        thisRoute.meta.notMenu ? thisRoute.meta.parent : thisRoute.path
      "
      class="el-menu-vertical-demo"
      :collapse="isCollapse"
      @open="open"
    >
      <template
        v-for="(item, index) in routers"
        v-if="item.meta.static || inArray(item.meta.unique, parentNames)"
      >
        <!-- <template v-for="(item, index) in routers"> -->
        <el-submenu
          v-if="item.children && !item.meta.alonePage"
          :key="index"
          :index="item.path"
        >
          <template slot="title">
            <i :class="item.meta.icon"></i>
            <span>{{ item.meta.title }}</span>
          </template>
          <!-- <router-link
            v-for="subItem in item.children"
            :to="{ path: subItem.path }"
            :key="subItem.path">
            <el-menu-item
              :index="subItem.path">
              {{ subItem.meta.title }}
            </el-menu-item>
          </router-link> -->
          <router-link
            v-for="subItem in item.children"
            v-if="
              (!subItem.meta.notMenu &&
                inArray(subItem.meta.unique, submenuNames[item.meta.unique])) ||
              item.meta.static
            "
            :to="{ path: subItem.path }"
            :key="subItem.path"
          >
            <el-menu-item :index="subItem.path">
              {{ subItem.meta.title }}
            </el-menu-item>
          </router-link>
        </el-submenu>
        <router-link
          v-else-if="!item.meta.notMenu"
          :to="{ path: item.path }"
          :key="index"
        >
          <el-menu-item :index="item.path" @click="clickAlonePage(item)">
            <i :class="item.meta.icon"></i>
            <span slot="title">{{ item.meta.title }}</span>
          </el-menu-item>
        </router-link>
      </template>
    </el-menu>
  </aside>
</template>

<script>
import { routerMap } from '@/router/routerMap'
import { mapGetters } from 'vuex'
import { inArray } from '@/libs/utils'

export default {
  props: {
    isCollapse: {
      type: Boolean,
      require: false,
      default: false
    }
  },

  data: () => {
    let item = ''
    for (let i = 0; i < routerMap[0].children.length; i++) {
      if (
        routerMap[0].children[i].children &&
        !routerMap[0].children[i].meta.alonePage
      ) {
        item = routerMap[0].children[i].path
        break
      }
    }
    return {
      routers: routerMap[0].children,
      firstIndex: item,
      parents: {},
      parentNames: [],
      submenuNames: {}
    }
  },

  computed: {
    thisRoute() {
      return this.$route
    },

    ...mapGetters(['menus'])
  },

  methods: {
    inArray: inArray,
    generateMenu() {
      this.menus.forEach(item => {
        if (item.parent_id === '0') {
          this.parents[item.id] = item
          this.parentNames.push(item.unique_en_name)
        }
      })

      this.menus.forEach(item => {
        if (item.parent_id !== '0' && this.parents[item.parent_id]) {
          if (
            Array.isArray(
              this.submenuNames[this.parents[item.parent_id].unique_en_name]
            )
          ) {
            this.submenuNames[this.parents[item.parent_id].unique_en_name].push(
              item.unique_en_name
            )
          } else {
            this.submenuNames[this.parents[item.parent_id].unique_en_name] = []
            this.submenuNames[this.parents[item.parent_id].unique_en_name].push(
              item.unique_en_name
            )
          }
        }
      })
    },
    // 获取后端菜单名
    getRouters() {
      for (let i of this.menus) {
        for (let item of this.routers) {
          // 二级菜单
          if (item.children && item.children.length > 0) {
            for (let k of item.children) {
              if (k.meta.unique === i.unique_en_name) {
                k.meta.title = i.menu_name || k.meta.title
                break
              }
            }
          }
          // 一级菜单
          if (item.meta.unique === i.unique_en_name) {
            item.meta.title = i.menu_name || item.meta.title
            break
          }
        }
      }
    },
    open(i) {
      this.firstIndex = i
    },
    clickAlonePage(item) {
      this.firstIndex = ''
    }
  },

  mounted() {
    this.generateMenu()
    this.getRouters()
  },
  watch: {
    $route() {
      let url = this.$route.path
      this.firstIndex = `/${url.split('/')[1]}`
    }
  }
}
</script>

<style lang="less">
.aside {
  height: 100%;
  overflow-y: scroll;
  .el-menu {
    height: 100%;
    .iconfont {
      vertical-align: middle;
      margin-right: 10px;
      width: 24px;
      text-align: center;
      font-size: 18px;
    }
  }
}
</style>
