<template>
  <nav class="header">
    <div v-if="showCollapse" class="collapse-btn" @click="handleSidebar">
      <i class="el-icon-menu"></i>
    </div>
    <div class="collapse-btn" v-else><i class="el-icon-s-home"></i></div>
    <div class="logo">聚禾美</div>
    <div class="header-right">
      <el-dropdown trigger="click" @command="handleCommand">
        <span class="el-dropdown-link">
          {{ user.name }}<i class="el-icon-arrow-down el-icon--right"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <!-- <el-dropdown-item command="changepassword">修改密码</el-dropdown-item>
          <el-dropdown-item :divided="true" command="password">修改个人信息</el-dropdown-item> -->
          <el-dropdown-item command="exit">退出登录</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <el-dialog :title="dialog_title" width="40%" :visible.sync="showImgPreview">
      <div v-if="dialog_title === '转介绍二维码'" class="image">
        <img :src="imgSrc">
      </div>
      <div v-else class="st-image">
        <div>
          <img :src="imgSrc.teacher">
          <p>老师二维码</p>
        </div>
        <div>
          <img :src="imgSrc.student">
          <p>学生二维码</p>
        </div>
      </div>
      <div slot="footer" class="search-button-container">
        <el-button type="primary" @click="showImgPreview = false">关闭</el-button>
      </div>
    </el-dialog>
  </nav>
</template>

<script>
import { authApi } from '@/api'
import { mapGetters, mapActions } from 'vuex'
import { USER_SIGNOUT } from '@/store/user'
export default {
  data: () => ({
    isCollapse: false,
    dialog_title: '',
    showImgPreview: false,
    imgSrc: ''
  }),
  props: {
    showCollapse: {
      type: Boolean,
      require: false,
      default: true
    }
  },
  methods: {
    ...mapActions([USER_SIGNOUT]),
    handleCommand(command) {
      switch (command) {
        case 'exit':
          let url = window.location.origin
          this.$message.success('退出登录')
          this.USER_SIGNOUT()
          this.$router.replace({
            name: 'login'
          })
          break
        case 'changepassword':
          console.log('修改密码');
          break
        case 'password':
          this.$router.push({
            name: 'changePassword'
          })
          break
        default: console.log('你是咋跳到这儿来的。。')
      }
    },

    handleSidebar() {
      this.isCollapse = !this.isCollapse
      this.$emit('sidebar', this.isCollapse)
    }
  },
  computed: {
    ...mapGetters(['user'])
  },
}
</script>

<style lang="less" scoped>
.header{
  .image{
    text-align: center;
  }
  .st-image {
    display: flex;
    justify-content: space-around;
    p {
      text-align: center;
    }
  }
  .collapse-btn{
    float: left;
    padding: 2px 20px 0;
    cursor: pointer;
    height: 60px;
    &:hover{
      background-color: #283446;
    }
    i{
      font-size: 20px;
    }
  }
  .logo{
    float: left;
    font-size: 21px;
  }
  .header-right{
    float: right;
    padding-right: 30px;
    .item{
      margin-right: 25px;
      i{
        font-size: 21px;
      }
    }
    .el-dropdown {
      color: #fff;
      .el-dropdown-link{
        cursor: pointer;
      }
    }
  }
}
</style>
